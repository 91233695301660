<template>
  <b-row>
    <b-col cols="12">
      <p class="primary fs-24 fw-bold py-2">Extractos</p>
    </b-col>
    <b-col cols="12">
      <b-form-row>
        <b-col cols="12" md="4">
          <b-form-group label="Número de obligación">
            <b-form-select v-model="creditoSelected" :state="$v.formE.credito.$dirty ? !$v.formE.credito.$error : null">
              <b-form-select-option :value="null">Seleccione número de obligación</b-form-select-option>
              <b-form-select-option
                :value="credito"
                :key="credito.numeroObligacion"
                v-for="credito in $store.getters.creditos"
              >
                {{ `Nro de Obligación ${credito.numeroObligacion} - ${credito.estado.toUpperCase()}` }}
              </b-form-select-option>
            </b-form-select>
            <b-form-invalid-feedback>{{ validarCampo($v.formE.credito) }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Año">
            <b-form-select
              :options="anios"
              @change="calcularMeses"
              v-model="$v.formE.anio.$model"
              :state="$v.formE.anio.$dirty ? !$v.formE.anio.$error : null"
            >
              <template #first>
                <b-form-select-option :value="null">Seleccione</b-form-select-option>
              </template>
            </b-form-select>
            <b-form-invalid-feedback>{{ validarCampo($v.formE.anio) }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Mes">
            <b-form-select
              :options="meses"
              v-model="$v.formE.mes.$model"
              :state="$v.formE.mes.$dirty ? !$v.formE.mes.$error : null"
            >
              <template #first>
                <b-form-select-option :value="null">Seleccione</b-form-select-option>
              </template>
            </b-form-select>
            <b-form-invalid-feedback>{{ validarCampo($v.formE.mes) }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-col>
    <b-col cols="12" class="content-end-center">
      <b-button variant="danger" @click="generarExtracto" :disabled="$v.$invalid">
        <i class="las la-search" /> Consultar
      </b-button>
    </b-col>
    <template v-if="archivo !== null">
      <b-col cols="12" class="pt-3">
        <b-form-tags
          size="sm"
          tag-pills
          tag-variant="info"
          limitTagsText="10"
          addButtonText="Agregar"
          :disableAddButton="true"
          placeholder="Agregar correo"
          v-model="correosNotificacion"
          :tag-validator="validarCorreo"
          addButtonVariant="outline-info"
          invalidTagText="Correo invalido"
          duplicateTagText="Correo repetido"
        />
      </b-col>
      <b-col cols="12" class="content-end-center pt-3">
        <b-button variant="danger" @click="enviarNotificacion" :disabled="correosNotificacion.length === 0">
          <i class="las la-paper-plane" /> Enviar
        </b-button>
      </b-col>
    </template>
    <b-col cols="12" lg="10" offset-lg="1" class="content-center my-3">
      <div class="content-center pointer-card-info">
        <div class="d-none d-sm-block">
          <embed v-if="archivo !== null" class="content-pdf" type="application/pdf" :src="archivo" />
          <img v-else src="@/assets/images/anothers/dummieExtractos.png" class="content-dummie" />
        </div>
        <div class="d-block d-sm-none py-4">
          <b-button variant="danger" :disabled="archivo === null" @click="descargarExtracto">Descargar</b-button>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import ReporteriaService from "@/app/core/api/reporteria";
import mixinsEvents from "@/app/shared/mixins/mixins-events";
import NotificacionService from "@/app/core/api/notificacion";
import AlertsService from "@/app/shared/services/alerts.service";

export default {
  mixins: [mixinsEvents],
  data() {
    return {
      anios: [],
      meses: [],
      archivo: null,
      archivoMail: null,
      creditoSelected: null,
      correosNotificacion: [],
      formE: { mes: null, anio: null, credito: null, email: this.$store.getters.correoElectronico },
    };
  },
  watch: {
    creditoSelected() {
      if (this.creditoSelected !== null) {
        this.calcularAnios();
        this.formE = { ...this.formE, mes: null, anio: null, credito: this.creditoSelected.numeroObligacion };
      } else {
        this.anios = [];
        this.meses = [];
        this.formE = { ...this.formE, mes: null, anio: null, credito: null };
      }

      this.$nextTick(() => this.$v.$reset());
    },
  },
  validations: {
    formE: { mes: { required }, anio: { required }, email: { required }, credito: { required } },
  },
  methods: {
    generarExtracto() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      ReporteriaService.generarExtracto(this.formE).then((response) => {
        const data = response.data;
        if (data.estado) {
          this.archivoMail = response.data.contenidoArchivo;
          let base64 = Buffer.from(data.contenidoArchivo, "base64");
          let blob = new Blob([base64], { type: "application/pdf", encoding: "utf-8" });
          this.archivo = URL.createObjectURL(blob);
          AlertsService.success("Extractos", data.mensaje);
        } else {
          this.archivo = null;
          if (data.mensaje === "El credito tiene anticipos para este periodo de tiempo.") {
            const msg = "Por favor comunícate con nuestras Líneas de Servicio al Cliente ProgreSER.";
            const mensajeInfo = this.makeAlertArray(msg, this.telefonos);
            AlertsService.error("Extractos", mensajeInfo);
          } else AlertsService.error("Extractos", data.mensaje);
        }
      });
    },
    calcularAnios() {
      this.anios = [];
      const date = new Date(this.creditoSelected.fechaDesembolso);
      for (let index = date.getFullYear(); index <= new Date().getFullYear(); index++) {
        this.anios.push({ text: index, value: index });
      }
    },
    calcularMeses() {
      this.meses = [];
      const date = new Date(this.creditoSelected.fechaDesembolso);

      if (this.formE.anio === date.getFullYear()) {
        this.$store.getters.listaMeses.map((item) => {
          if (date.getMonth() + 1 <= item.value) this.meses.push(item);
        });
      } else if (this.formE.anio === new Date().getFullYear()) {
        this.$store.getters.listaMeses.map((item) => {
          if (new Date().getMonth() + 1 >= item.value) this.meses.push(item);
        });
      } else this.meses = this.$store.getters.listaMeses;
    },
    enviarNotificacion() {
      const formI = {
        listaConCopia: [],
        valoresReemplazo: [],
        listaConCopiaOculta: [],
        extensionAdjunto: ".pdf",
        base64Adjunto: this.archivoMail,
        anio: this.formE.anio.toString(),
        nombreInforme: "EXTRACTO_CUENTA",
        listaDestinatario: this.correosNotificacion,
        nombreCliente: this.creditoSelected.nombreCompleto,
        mes: this.$store.getters.listaMeses.find((item) => item.value == this.formE.mes).text,
      };

      NotificacionService.enviarNotificacion(formI).then((response) => {
        AlertsService.success("Extractos", response.data.mensaje);
      });
    },
    descargarExtracto() {
      let element = document.createElement("a");
      element.setAttribute("href", `data:application/pdf;base64,${this.archivoMail}`);
      element.setAttribute("download", `Extracto`);
      document.body.appendChild(element);
      element.click();
    },
  },
};
</script>
